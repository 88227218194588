import React from "react";
import "./Join.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i0ckv86",
        "template_au0nf5p",
        form.current,
        "UX8cmufq7k3IYDiiC"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="join" id="join-us">
        <div className="left-j">
          <hr />
          <div>
            <span className="stroke-text">READY TO</span>
            <span>LEVEL UP</span>
          </div>
          <div>
            <span>YOUR BODY</span>
            <span className="stroke-text">WITH US?</span>
          </div>
        </div>
        <div className="right-j">
          <form
            onSubmit={sendEmail}
            ref={form}
            action=""
            className="email-container"
          >
            <input
              type="email"
              name="user_email"
              placeholder="Enter your email addres"
            />
            <button className="btn btn-j">Join Now</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Join;
